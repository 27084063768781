.content-box {
  grid-column: 2/-2;
}

.page-content {
  padding-block: 90px 76px;
}

.proposal-line-section-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(164px, 214px));
  gap: 24px;
}

.title {
  grid-column: 1 / -1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  @media (width < 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
