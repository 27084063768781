/** Should use `displayable` naming instead of `artObject` */

.close-button {
  display: flex;
  width: 60px;
  gap: 8px;
}

.art-object-detail-view {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  gap: 40px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 28px;
  z-index: 100;
}

.art-object-detail-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  align-items: center;
}

.art-extra-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  gap: 4px;
}

.art-object-thumbnail {
  width: auto;
  max-width: 362px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  img {
    border-radius: 16px;
    max-width: 100%;
  }

  div {
    height: 362px;
  }
}

.art-object-specifications {
  display: flex;
  flex-direction: column;
}

.metas {
  gap: 2px;
  margin-block: 4px 40px;
  display: flex;
  flex-flow: column nowrap;
}

.meta {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-neutral-600);
}

.icon {
  color: var(--color-neutral-500);
  height: 16px;
  margin-left: 6px;
}

.fancy-dot {
  color: var(--color-brand-quarternary-300);

  &.gray {
    color: var(--color-neutral-500);
  }
}

.divider {
  width: 90%;
}

.modal-close-icon {
  cursor: pointer;
}

.lightbox-image {
  max-width: 90vw;
  max-height: 90vh;
}

@media (width >=769px) {
  .art-object-detail-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .art-object-detail-view {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.fade {
  mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.MuiCollapse-hidden {
  color: red;
}
