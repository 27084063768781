.image-wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.image-wrapper div {
  display: grid;
  grid-area: -1 / 1;
  justify-self: end;
}

.displayable-image {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  object-position: center center;
  overflow: hidden;
  width: 100%;
  height: 88%;
  display: flex;
  justify-content: center;
  background-color: #D8E1EF;
}

.pills-container {
  display: flex;
  gap: 12px;
  margin: 12px;
  align-items: center;
}

.pill {
  height: 4px;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 80px;
}

.seen {
  background: rgba(255, 255, 255);
}

.active {
  height: 6px;
  background: rgba(255, 255, 255);
}

.controls-container {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
}

.controls-nav-container {
  display: flex;
}
